window.$ = window.jQuery = require('jquery');

$(document).ready(function () {


    $('#emailCheckbox').change(function(){

        let $emailInput =  $('#emailInputGroup');

        console.log('change', this.checked);

        this.checked ? $emailInput.addClass('show') : $emailInput.removeClass('show');


    }).change();


});